<template>
  <div>
    <CNavbar
        expandable="md"
        color="white"
    >
      <div class="container mt-4">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center btn text-left">
            <router-link to="/pages/login" class="text-main_black">
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/logo_btc_mono 1.svg" alt="">
                <span class="ml-2">Professional cryptocurrency<br/>
mining solutions</span>
              </div>
            </router-link>
          </div>
          <div class="d-flex align-items-center">
            <div class="links">
            </div>
            <div class="social">
              <img src="@/assets/icons/ic_facebook.svg" alt="" class="mx-2">
              <img src="@/assets/icons/ic_insta.svg" alt="" class="mx-2">
            </div>
            <div>
              <a href="" class="mx-2 text-main_black">РУСС</a>
              <a href="" class="mx-2 text-main_black">ENG</a>
            </div>
          </div>
        </div>
      </div>
    </CNavbar>
    <div class="c-app flex-row ">
      <CContainer>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard class="p-4 border-0">
                <CCardBody>
                  <p class="text-center my-3" v-if="loading">
                    <CSpinner grow size="md"/>
                  </p>
                  <CForm @submit="submitForm" v-else>
                    <h1 class="text-main_black font-2xl font-weight-normal">Enter a new password</h1>
                    <div class="mt-lg-4">
                      <p class="d-flex flex-column mb-3">
                        <label for="password" @click="showPassword(0)" class="d-flex justify-content-between">
                          <span>Password</span>
                          <span class="pointer-event btn p-0"><img src="@/assets/icons/ic_view.svg" alt="">{{ hides[0] }}</span>
                        </label>
                        <input :type="shows[0]" required id="password" v-model="forms.password" placeholder="Password" :class="(error.message ? 'border-error_color' : '') + ' p-2 pl-3 bg-milk_mix border rounded-lg outline-none text-dark'"/>
                        <CListGroup class="mt-2 flex-row flex-wrap font-sm helpers ">
                          <li v-for="(obj,id) in Object.keys(helpers)" :key="id" :class="(helpers[obj].isComplete ? 'text-gray-simple' : (helpers[obj].error ? ' text-error_color' : 'text-gray'))">
                            {{ helpers[obj].text }}
                          </li>
                        </CListGroup>
                      </p>
                      <p class="d-flex flex-column mb-4">
                        <label for="confirm_password" @click="showPassword(1)" class="d-flex justify-content-between">
                          <span>Repeat new password</span>
                          <span class="pointer-event btn p-0"><img src="@/assets/icons/ic_view.svg" alt="">{{hides[1]}}</span>
                        </label>
                        <input :type="shows[1]" v-model="forms.password_confirm" required id="confirm_password" placeholder="Reset password" :class="(error.message ? 'border-error_color' : '') + ' p-2 pl-3 bg-milk_mix border rounded-lg outline-none text-dark'"/>
                        <span class="text-error_color mt-1" v-if="error.message">{{ error.message }}</span>
                      </p>
                    </div>
                    <CButton color="submit" @click="submitForm" :disabled="!(forms.password_confirm.length > 0 && this.isCompleted)" :class="' text-white p-2 mt-2 w-100 rounded-md'" shape="square">SAVE</CButton>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Trans } from "../../plugins/Translation";

export default {
  name: 'Reset_password',
  data () {
    return {
      password: '',
      loading: false,
      langs: Trans.supportedLocales,
      shows: {
        0: 'password',
        1: 'password'
      },
      hides: {
        0: 'Show',
        1: 'Show'
      },
      buttonActivate: false,
      error: {
        message: null
      },
      helpers: {
        lowercase: {
          text: 'One lowercase character',
          isComplete: false,
          regex: /[a-z]/
        },
        special: {
          text: 'One special character',
          isComplete: false,
          regex: /(?=.*[!@#$%^&*])/,
        },
        uppercase: {
          text: 'One uppercase character',
          isComplete: false,
          regex: /(?=.*[A-Z])/
        },
        minimum: {
          text: '8 characters minimum',
          isComplete: false,
          regex: /(?=.{8,})/
        },
        numeric: {
          text: 'One number',
          regex: /(?=.*[0-9])/,
          isComplete: false
        }
      },
      forms: {
        password: "",
        email: "",
        password_confirm: ""
      },
      isCompleted: false
    }
  },
  watch: {
    'forms.password': {
      handler: function (e){

        this.isCompleted = !(e.length === 0 || this.forms.email.length === 0);

        this.isCompleted = true;
        Object.keys(this.helpers).forEach(obj => {
          this.helpers[obj].isComplete = this.helpers[obj].regex.test(e);
        });
      },
      immediate: true
    },
  },
  methods: {
    inputsAreValid: function() {
      return this.emailIsValid() && this.passwordIsValid();
    },
    showPassword(id){
      this.shows[id] = this.shows[id] === 'text' ? 'password' : 'text';
      this.hides[id] = this.hides[id] === 'Show' ? 'Hide' : 'Show';
    },
    changeLanguage(locale){
      Trans.changeLocale(locale);
    },
    submitForm(e){
      e.preventDefault();
      const keys = Object.keys(this.helpers);
      let exist = false;
      for (let x = 0;x < keys.length;x++){
        if(!this.helpers[keys[x]].isComplete){
          this.helpers[keys[x]] = {
            ...this.helpers[keys[x]],
            error: true
          }
          exist = true;
        }
      }
      if(exist) return;
      if(this.forms.password !== this.forms.password_confirm){
        this.error.message = "Passwords does not match, please try again.";
        this.buttonActivate = false;
        return;
      }
      this.loading = true;
      axios({
        method: 'post',
        url: process.env.VUE_APP_API+'/api/v1/password/sendReset',
        data: {
          password: this.forms.password,
          password_confirmation: this.forms.password_confirm,
          token: this.$route.params.token,
          email: this.forms.email
        },
      })
          .then(() => {
            this.$router.push('/pages/login?status=success');
          })
          .catch( (error) => {
            if(error.response.status === 403) this.$router.push('status/error')
            if(error.response.data.error){
              this.error.message = error.response.data.error[0];
              return;
            }
            this.error.message = error.response.data.message;
          })
          .then(()=>{
            this.loading = false;
          });
    },
    emailIsValid: function() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    passwordIsValid: function() {
      const re = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,16}$/
      return re.test(this.password)
    },
  }
}
</script>